import React, { useState, useEffect, useRef, useMemo } from "react";
import { Empty } from "antd";
import useRetailers from "../../../hooks/useRetailers";

import { ArrowSelect } from "assets/icons/icons";
import search from "assets/images/search.svg";
import { useSelector } from "react-redux";
import { flattenHistoricAlertBrands } from "utils/flattenHistoricAlertBrands";
import { Checkbox } from "antd";

const FiltersAlert = ({
  item,
  selectItems,
  setFiltersParams,
  textSelectStyles,
  setOnclickId = () => {},
  onlyDropdown,
  setCurrentForm,
  index,
  handleNextForm,
  hasAllCheckbox,
}) => {
  const { name, label, placeholder, nameParam, data, customLabelName, notIncludeSearch, notIncludeCount } = item;
  const wrapperRef = useRef(null);

  const [selectedItems, setSelectedItems] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const { getRemoteLogo } = useRetailers();
  const { alert, alerts } = useSelector((state) => state.alerts);

  const obsoleteAlertBrands = flattenHistoricAlertBrands(alerts, alert);
  const [historicBrands, setHistoricBrands] = useState(obsoleteAlertBrands);

  useEffect(() => {
    setSelectedItems(selectItems);
    setHistoricBrands(historicBrands);
    if (selectItems.includes("All")) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [selectItems]);

  const selectedCountryItems = useMemo(() => {
    if (selectAll) {
      return ["All"];
    }
    return placeholder === "retailer"
      ? !!data && data.filter((el) => selectedItems.includes(el.id)).map((el) => el.id)
      : selectedItems;
  }, [data, placeholder, selectedItems, selectAll]);

  const onSearch = (data) => {
    let dataArray = [];
    if (Array.isArray(data)) {
      dataArray = data;
    } else if (typeof data === "object" && data !== null) {
      if (data.userProductGroup && Array.isArray(data.userProductGroup)) {
        dataArray = dataArray.concat(data.userProductGroup);
      }
      if (data.companyProductGroup && Array.isArray(data.companyProductGroup)) {
        dataArray = dataArray.concat(data.companyProductGroup);
      }
    }

    const filteredData = dataArray.filter((item) => {
      if (searchValue) {
        return item[nameParam] && item[nameParam].toLowerCase().includes(searchValue.toLowerCase());
      }
      return item;
    });
    return filteredData;
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const handleSelectItem = (id) => {
    if (!selectAll) {
      if (selectedCountryItems.includes(id)) {
        const ids = selectedCountryItems.filter((item) => item !== id);
        setSelectedItems(ids);
        setOnclickId(ids);
      } else {
        const ids = [...selectedCountryItems, id];
        setSelectedItems(ids);
        setOnclickId(ids);
      }
    }
  };

  const handleSelectedAll = (data) => {
    const filterData = item.data;
    if (Array.isArray(filterData)) {
      const ids = item.data.map((item) => item.id);
      setSelectedItems(ids);
    } else if (typeof filterData === "object" && filterData !== null) {
      let ids = [];
      if (filterData.userProductGroup && Array.isArray(filterData.userProductGroup)) {
        ids = ids.concat(filterData.userProductGroup.map((item) => item.id));
      }
      if (filterData.companyProductGroup && Array.isArray(filterData.companyProductGroup)) {
        ids = ids.concat(filterData.companyProductGroup.map((item) => item.id));
      }
      setSelectedItems(ids);
    }
  };

  const handleApply = () => {
    setFiltersParams(name, selectedCountryItems);
    !selectedCountryItems.includes(historicBrands) && setHistoricBrands([]);
    setOpenDropdown(false);
  };

  const handleNext = () => {
    handleNextForm(name, selectedCountryItems);
  };

  const handleSelectAllCheckbox = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedItems([]);
    }
  };

  const selectedItemsIcon = () => {
    let dataArray = [];
    if (Array.isArray(data)) {
      dataArray = data;
    } else if (typeof data === "object" && data !== null) {
      if (data.userProductGroup && Array.isArray(data.userProductGroup)) {
        dataArray = [...dataArray, ...data.userProductGroup];
      }
      if (data.companyProductGroup && Array.isArray(data.companyProductGroup)) {
        dataArray = [...dataArray, ...data.companyProductGroup];
      }
    }

    const items = dataArray.filter((item) => selectedItems.includes(item.id));
    return (
      <div className="selected-list">
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {placeholder === "retailer" ? (
              <img
                src={getRemoteLogo((item[nameParam] || "").replaceAll(" ", "_"))}
                alt="retailer"
                style={{ zIndex: index, right: index * 10 }}
              />
            ) : (
              <div style={{ background: item.color }}></div>
            )}
          </React.Fragment>
        ))}
      </div>
    );
  };

  const renderMinorityFilters = (data) => {
    const getTitle = {
      userProductGroup: "My Custom Groups",
      companyProductGroup: "Company-Wide Custom Groups",
    };

    return (
      Object.entries(data).length !== 0 &&
      Object.keys(data).map((productItem, productIndex) => (
        <div key={productIndex}>
          <div className={`count-items ${productItem}`}>{getTitle[productItem]}</div>
          {data[productItem].length ? (
            onSearch(data[productItem]).map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => handleSelectItem(item.id)}
                  className={`select-item-box ${selectedCountryItems.includes(item.id) ? "selected-item-box" : ""}`}
                >
                  <span className="select-color" style={{ background: `${item.color}` }}></span>
                  <span style={{ textTransform: "capitalize" }} className="select-name">
                    {item[nameParam]}
                  </span>
                  <span className="select-count">({item.productsCount})</span>
                </div>
              );
            })
          ) : (
            <div className="no-data">No Data</div>
          )}
        </div>
      ))
    );
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      if (openDropdown) {
        function handleClickOutside(event) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpenDropdown(false);
          }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }
    }, [ref, openDropdown]);
  }
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef} className="wrapper-custom-select-box">
      {onlyDropdown ? null : (
        <div className="wrapper-custom-select" onClick={() => setOpenDropdown(!openDropdown)}>
          <div className="custom-select-label" style={{ backgroundColor: openDropdown ? "#d9dfe6" : "#eef2f7" }}>
            <div className={`select-label ${selectedItems.length ? "selected-label" : ""}`} style={textSelectStyles}>
              <div className="label">{customLabelName ? customLabelName(selectedCountryItems.length) : label}</div>
              {selectedItems.length && !customLabelName ? (
                <div className="selected-length">({selectAll ? "All" : selectedCountryItems.length})</div>
              ) : null}
            </div>
            <div className="hint-selected-item">
              {selectedItems.length ? selectedItemsIcon() : null}
              <ArrowSelect />
            </div>
          </div>
        </div>
      )}

      {openDropdown || onlyDropdown ? (
        <div
          className="custom-select-dropdown"
          style={{
            position: onlyDropdown ? "static" : "absolute",
            width: onlyDropdown ? "540px" : "320px",
          }}
        >
          {notIncludeSearch ? null : (
            <div className="search-item">
              <img src={search} alt="search" />
              <input onChange={handleSearch} value={searchValue} placeholder={`Search for a ${placeholder}`} />
            </div>
          )}
          {notIncludeCount ? null : (
            <div className="count-items">
              <div>
                {data.length} {label}
              </div>
              <div>{selectAll ? "All" : selectedCountryItems.length} Selected</div>
              {placeholder === "brand" && historicBrands.length > 0 && (
                <div style={{ fontStyle: "italic" }}>({historicBrands.length} Historic)</div>
              )}
            </div>
          )}
          <div className="select-lists">
            {hasAllCheckbox && (
              <div className="select-item-box">
                <Checkbox checked={selectAll} onChange={handleSelectAllCheckbox}>
                  Select All (including NPDs)
                </Checkbox>
              </div>
            )}
            {Array.isArray(data) ? (
              <>
                {data.length ? (
                  onSearch(data).map((item, index) => (
                    <div
                      key={index}
                      className={`select-item-box ${
                        selectedCountryItems.includes(item.id) ? "selected-item-box" : ""
                      } ${selectAll ? "disabled" : ""}`}
                      onClick={() => handleSelectItem(item.id)}
                    >
                      <div className="select-item">
                        {placeholder === "retailer" ? (
                          <img src={getRemoteLogo((item[nameParam] || "").replaceAll(" ", "_"))} alt="retailer" />
                        ) : (
                          <div style={{ background: item.color }} className="select-color"></div>
                        )}
                        <div className="select-name">{item[nameParam]}</div>
                      </div>
                      {item.hasOwnProperty("productsCount") ? (
                        <div className="select-count">({item.productsCount})</div>
                      ) : null}
                    </div>
                  ))
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </>
            ) : (
              renderMinorityFilters(data)
            )}
          </div>
          <div className="control-select-btn">
            {selectedCountryItems.length === data.length || selectedCountryItems.length > data.length ? (
              <div onClick={() => setSelectedItems([])}>Deselect All</div>
            ) : selectedCountryItems.length !== data.length && selectedCountryItems.length > 0 ? (
              <div
                onClick={() => {
                  setSelectedItems([]);
                  setSelectAll(false);
                }}
              >
                Reset
              </div>
            ) : (
              <div onClick={handleSelectedAll}>Select All Current</div>
            )}

            {onlyDropdown ? null : (
              <div className="apply" onClick={handleApply}>
                Apply
              </div>
            )}
          </div>
        </div>
      ) : null}
      {onlyDropdown ? (
        <div className="wrapper-control-btn">
          {index !== 0 ? (
            <div className="default-btn" onClick={() => setCurrentForm(index - 1)}>
              Previous
            </div>
          ) : null}
          {index === 3 ? (
            <div className="primary-btn" onClick={handleApply}>
              Save
            </div>
          ) : (
            <div className="primary-btn" onClick={handleNext}>
              Next
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default FiltersAlert;
