import React, { useEffect, useState } from "react";
import { Slider } from "antd";

const SliderBox = ({ values, setValues }) => {
  const [minmax, setMinmax] = useState({ min: 0, max: 100 });

  useEffect(() => {
    setMinmax({ min: values[0], max: values[1] });
  }, []);

  const handleSliderChange = (newValues) => {
    if (minmax.min === 1) {
      setValues([1, newValues[1]]);
    } else {
      setValues(newValues);
    }
  };

  return (
    <div>
      <Slider range min={minmax.min} max={minmax.max} value={values} onChange={handleSliderChange} />
      <div className="wrapper-slider-inputs">
        <input type="text" disabled={true} value={values[0]} />
        <div className="separate">-</div>
        <input type="text" disabled={true} value={values[1]} />
      </div>
    </div>
  );
};

export default SliderBox;
